button:hover {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}
.lh {
  line-height: 1.4rem;
}

.my-half {
  margin-top:  0.5rem;
  margin-bottom: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-1-hf {
  margin-bottom: 1.5rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}

.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}

.px-1{
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-1{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.hide {
  display: none!important;
}

.modalShow {
  display: flex !important;
}
.show {
  display: flex !important;
}

.show-block {
  display: inline-block;
}

.fileInputs {
  border: 2px solid #047b40;
  background: #047b40;
  color: white;
}

.fileInputs:hover {
  cursor: pointer;
  border: 2px solid #047b40;
  background: white;
  color: #047b40;
}

.fileInputs label:hover {
  cursor: pointer;
}

.progress {
  min-width: 10rem;
  /* background-color: #f5f5f5; */
  background-color: #e2fef0;
  border-radius: 3px;
  box-shadow: none;
  position: absolute;
  top: 0rem;
  left: 50%;
  margin-left: -5rem;
  z-index: 2233;
}

.progress-bar {
  background-color: #2196f3;
  box-shadow: none;
  padding: 0.1rem;
}
.progress-bar {
  padding: 0.1rem;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}

.progress-bar-success {
  background-color: #64dd17;
}

#nav-toggle {
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  padding: 0;
  display: block;
  width: 3.4rem;
  height: 3.4rem;
  background: #047b40;
  border-radius: 150px;
}

#nav-toggle span,
#nav-toggle span:before {
  cursor: pointer;
  border-radius: 60px;
  height: 4px;
  width: 30px;
  background: white;
  position: absolute;
  top: 50%;
  display: block;
  content: "";
}

#nav-toggle span {
  left: 50%;
  margin: -2px 0 0 -15px;
  transform: rotate(180deg);
}
#nav-toggle span {
  left: 50%;
  margin: -2px 0 0 -15px;
  transform: rotate(180deg);
}
#nav-toggle span {
  left: 50%;
  margin: -2px 0 0 -15px;
  transform: rotate(180deg);
}

#nav-toggle span:hover {
  transform: rotate(180deg);
}

#nav-toggle span:before {
  transform: rotate(450deg);
  top: 0;
}

#nav-toggle,
#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  transition: all 750ms cubic-bezier(0.845, -0.27, 0.025, 1.305);
}

#nav-toggle:hover span:before {
  transform: rotate(90deg);
}

.toggleRadioContainer {
  display: flex;
  border: 1px solid grey;
  border-radius: 4px;
}

.radioBtn {
  padding: 0.5rem 0.8rem;
  border: none;
  background: none;
}

.isSelected {
  background: #047b40;
  color: white;
}

table {
  width: 100% !important;
}

/* table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
} */

/* table tr {
  border-bottom: 1px solid #e7e9eb;
   box-shadow: 0 2px 4px 0 rgb(183 192 206 / 40%);
    -webkit-box-shadow: 0 2px 4px 0 rgb(183 192 206 / 40%);
    -moz-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
    overflow: auto;
    border-radius: 5px;
    background: #fff;
 
} */

/* @media only screen and (min-width: 992px) {
  table th,
  table td {
    text-align: center;
  }
} */

/* table th {
  background: #e2fef0;
    color: #047b40;
  letter-spacing: .1em;
  text-transform: uppercase;
} */

@media screen and (max-width: 600px) {
  /* table {
    border: 0;
  } */

  /* table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  } */

  /* table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  } */

  /* font-size: .8em; */

  /* table td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
  } */

  /* text-transform: uppercase; */
  /* table td::before {  
    content: attr(aria-label);
    content: attr(data-label);
    float: left;
    font-weight: bold;
  } */

  /* table td:last-child {
    border-bottom: 0;
  } */
}

.modalbtngroup {
  margin-top: 1rem;
  justify-content: center;
  display: flex;
}

.modalbtngroup button {
  padding: 0.6rem 1.1rem;
  border: 1px solid #047b40;
  border-radius: 5px;
}

.yayBtn {
  margin-right: 0.7rem;
  background-color: #047b40;
  color: white;
}

.nayBtn {
  margin-left: 0.7rem;
  background-color: white;
  color: #047b40;
}

.modalOverlay {
  display: flex;
  align-items: center;
}

.modalCard {
  background: white;
  box-shadow: 0 0 10px 0 rgb(183 192 206 / 40%);
  display: flex;
  padding: 30px;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: auto 1rem;
}

/* Iner Menu Loading Animation */

.loadingmenu {
  display: flex;
  justify-content: center;
}
.loadingmenu div {
  width: 1rem;
  height: 1rem;
  margin: 2rem 0.3rem;
  background: #047b40;
  /* background: #e2fef0; */
  border-radius: 50%;
  -webkit-animation: 0.9s bounce infinite alternate;
  animation: 0.9s bounce infinite alternate;
}
.loadingmenu div:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.loadingmenu div:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}

.loadingmain {
  width: 2rem;
  height: 2rem;
  margin: 2rem;
  border-radius: 50%;
  border: 0.3rem solid #047b40;
  transform: translate(50%);
  -webkit-animation: 1s loadingmain ease-out infinite;
  animation: 1s loadingmain ease-out infinite;
}

@-webkit-keyframes loadingmain {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loadingmain {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.paginateDiv{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.pagination{
  display: flex;
  align-items: center;

}
.pagination li{
  padding: 0.2rem .9rem; 
  border-radius: 4px;
  margin:auto .9rem;
}
.pagination li:hover{
  cursor: pointer;
  background: #8bc5a8;
}
.activePage{
  color:honeydew;
  background:#047b40 ;
}
.activePage:hover{
  background-color: #047b40;
}

.previous{ 
  margin-right: 2rem;
}
.next{
  margin-left: 2rem;

}
.previous, .next{
  color:black;
  background: grey;
}


.createCustomer{
  cursor: pointer;
  font-size: 1rem;
  color:#047b40;
  float: right;
}

.showMobileHeader{
  left : 0px!important;
}

.hideMobileHeader{
  left:-300px!important;
}

.centerInDiv{
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeStyle {
  cursor: pointer;
  border-radius: 0 50px 50px 0;
  background: #e2fef0;
  color: #047b40;
  transition: 0.8s ease-in-out;
}

.shareIcon{
  border-radius: 5px;
  padding:0.34rem 0.34rem;
  background:white;
  border: 1.5px solid #dadce0;
}
.shareIcon:hover{
  cursor: pointer;
  background: inherit;
}

.disabled{
  opacity: 0.5;
}




.whatsapp-ico{
  fill: white;
  width: 2.2rem;
  height: 2.2rem;
  padding: 3px;
  background-color: #4dc247;
  border-radius: 50%;
}

.whatsapp-ico:hover{
  box-shadow: 2px 2px 11px rgba(0,0,0,0.7);
}