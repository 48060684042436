* {
	box-sizing: border-box !important;
}

.main {
	/* height: 400px; */
}

.illustration  h3{
	font-size: 26px;
	font-weight: 500;
}
.illustration p {
	font-size: 14px;
}

.svg {
	height: 500px;
}


.form-container {
    box-shadow: 0px 1px 5px 0px rgb(183 192 206 / 50%);
	width: 75%;
}
.inputField {
	border-radius: 10px;
	height: 45px;
	outline: none !important;
	border: 1px solid e5e5e5 !important;
}
.form-control:focus, .form-select:focus {
	border-color: #28a745;
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.btn-register {
	background-color: #047b40;
	color: #fff;
}

.btn-register:hover {
	color: #fff;
	opacity: .8;
}

.sign {
	font-size: 14px;
}

.sign a {
	color: #047b40;
	text-decoration: none;
}
.logo-container-sm {
	display: none;
}

@media (max-width: 575.98px) {
	.illustration {
		display: none;
	}
	.form-container {
		width: 100%;
		/* margin-left: 1rem; */
	}
	.logo-container-sm {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

/* business profile */
.biz-container {
	/* height: 200px; */
}

.logo-container-biz {
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-text {
	text-align: center !important;
	font-size: 26px;
}
.biz-inner {
	border-radius: 7px;
	/* padding: 7px 0 0 7px; */
}
.illustration-success {
    width: 40rem;
    margin: auto;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 800px) {
    .illustration-success {
        width: 30rem;
    }
}

@media (max-width: 575.98px) {
	.biz-inner {
		width: 100% !important;
	}
}
.bg-green {
	background-color: #23B9A9;
}
.blue-bg {
	background-color: #4AA9E9;
}
.purple-bg {
	background-color: #62549A;
}
.red-bg {
	background-color: #FF6C60;
}
/* Dynamic Bg*/
.bg0 {
	background-color: #FF6C60;
}
.bg1 {
	background-color: #62549A;
}
.bg2 {
	background-color: #4AA9E9;
}
.bg3 {
	background-color: #23B9A9;
}
/* Dynamic bg ends */
.card-custom {
	height: 200px;
	box-shadow: 0 2px 4px 0 rgb(183 192 206 / 40%);
    border-radius: 10px;
	color: #fff;
	border: none;
	position: relative;
}
.icon-size {
	font-size: 20px;
}
.height-sm {
	height: 100px !important;
}
.card-loader-container{
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	align-self: center;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
  }
  @keyframes lds-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }