@import "utilcss.css";
* {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
}

/* fonts */
@font-face {
  font-family: "Proxima Nova";
  src: url(./assets/proxima_ssv/Proxima\ Nova\ Alt\ Light.otf);
}

:root {
  --color: #047b40;
  --green-color: #047b40;
}

body {
  overflow: auto !important;
}

.radio--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 223px;
}

.radio {
  font-size: 2.25rem;
  color: var(--color);
  display: flex;
  gap: 0.2em;
  align-items: center;
  max-width: 100%;
}

.radio__input {
  display: flex;
}

input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="checkbox-radio"] {
  visibility: visible;
}

.radio__control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 0.1em solid currentColor;
}

input:hover + .radio-check__control {
  cursor: pointer;
}

input[type="radio"] + .radio__control::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  box-shadow: inset 0.6em 0.6em currentColor;
  border-radius: 50%;
  transition: 180ms transform ease-in-out;
  transform: scale(0);
}

input:checked + .radio__control::before {
  transform: scale(1);
}

input:hover + .radio__control {
  cursor: pointer;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

/* STYLES ARE ARRANGED ALPHABETICALLY */

a {
  outline: none;
  text-decoration: none;
}

.access-control__container-card {
  background: white;
  min-width: 325px;
  max-width: 500px;
  padding: 10px;
  border-radius: 10px;
}

.access-control__list {
  display: grid;
  gap: 1em;
}

.access-control__title {
  display: flex;
}

.access-control__item-section {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
}

.access-control__item-section:hover {
  cursor: pointer;
}

.access-control__item-section:focus + .access-control__item-section-list {
  display: block !important;
}

.access-control__item-section-list {
  display: none;
}

.access-control__item-section
  > .flex--container
  > .checkbox__container
  > span
  > .checkbox
  > .checkbox__input
  > .checkbox__control
  > svg {
  background: #047b40;
}

.access-control__item-section
  > .flex--container
  > .checkbox__container
  > span
  > .checkbox
  > .checkbox__input
  > .checkbox__control
  > svg
  > path {
  stroke: #fff;
}

.access-control__item-section__dropdown-arrow {
  clip-path: polygon(0 25%, 100% 25%, 50% 100%);
  display: block;
  width: 12px;
  height: 12px;
  background: #047b40;
}

.access-control__item-section__dropdown-arrow--container {
  width: 100px;
}
.account-details__container {
  align-self: flex-end;
  width: 320px;
  margin-top: 20px;
}

.address {
  display: flex;
  justify-content: space-between;
}

.admin > .text > span {
  display: flex;
  text-align: center;
}

.employee > .text > span {
  display: flex;
  text-align: center;
}

.address--container {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.attachments {
  width: 900px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.attach-pdf {
  display: flex;
  justify-content: space-between;
  width: 129px;
  align-items: center;
  border-radius: 2px;
  padding: 8px;
  height: auto;
  background: white;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
}

.auth--container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.auth-card__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  justify-items: center;
  align-items: flex-start;
  height: 97vh;
  padding-top: 60px;
}

.auth-container__verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: 100vh;
  padding: 5rem;
}

.auth-error-text {
  max-width: 330px;
  min-width: auto;
}

.avatar {
  height: 65px;

  border-radius: 50%;
}

.blue-text__table {
  color: #1200fe;
}

.body--container {
  /* width: 70%; */
  margin: auto;
}

.border-grey {
  border-bottom: 1px solid #e7e9eb;
}

.bordered__table {
  width: auto;
  width: -moz-available;
  border-collapse: collapse;
  width: 100%;
}

.border-rounded {
  border-radius: 50px !important;
}

.border-circle {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  text-align: center;
}

.green-light__border {
  border: 1px solid #06c567;
}

.orange-border {
  border: 1px solid #fe5824;
}

.purple-border {
  border: 1px solid #5d24fe;
}

button > svg {
  height: 24px;
  width: 24px;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.btn-borderless {
  background: transparent;
  border: none;
}

.btn-light-green__dashboard {
  background-color: #06c567;
  text-decoration: none;
  width: 170px;
  height: 45px;
  border: 1px solid#06c567;
  display: flex;
  align-items: center;

  justify-content: center;
  border-radius: 40px;
  color: white;
}

.btn-light-green__dashboard:hover {
  background-color: white;
  color: #554e4e;
}

.btn--container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-and-info__container {
  display: flex;
  justify-content: space-between;
  align-self: baseline;
  width: 151px;
  height: 50px;
  margin-right: 35px;
}

.btn-add__container {
  margin: 30px 0 0 0;
}

.btn-dropdown {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-dropdown__borderless {
  border: none;
  background: transparent;
  cursor: pointer;
}

.btn-dropdown__borderless:focus + .links-table {
  display: grid;
}

.btn-dropdown__borderless > svg {
  width: 28px;
  height: 28px;
}

.btn-dropdown__borderless:hover > svg {
  opacity: 0.6;
}

.btn-dropdown__borderless > svg > path {
  fill: #047b40;
}

.btn-dropdown:focus + .links-table {
  display: grid;
}

.btn-dropdown:hover {
  cursor: pointer;
}

.btn-green__dashboard {
  width: 155px;
  height: 45px;
  border: 1px solid #06c567;
  background-color: white;
  color: #047b40;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 13px;
}

.btn-green__dashboard:hover {
  color: #047b40;
  background-color: #f7f8fa;
  transition: 0.3s ease-out;
}

.btn-green__dashboard-square {
  width: 200px;
  height: 45px;
  background-color: #06c567;
  border: 1px solid #06c567;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn-green__dashboard-square:hover {
  opacity: 0.8;
  cursor: pointer;
}

.btn-green__square {
  width: 7vw;
  height: 45px;
  background-color: #047b40;
  border: 1px solid #047b40;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn-light {
  color: #047b40;
  font-size: 17px;
  font-weight: 200;
}

.btn-list__table {
  background: none;
  border: none;
  width: auto;
  height: fit-content;
}

.btn-toggle-green {
  background: #06c567;
  height: 35px;
  border: 1px solid #06c567;
  border-radius: 0px 6px 6px 0px;
  width: 115px;
  color: #fff;
  cursor: pointer;
}



.btn-toggle-light {
  background: #fff;
  height: 35px;
  border: 1px solid #e5e5e5;
  border-radius: 6px 0 0 6px;
  width: 100px;
  color: #000;
  cursor: pointer;
}


.btn-white__dashboard {
  text-decoration: none;
  width: 180px;
  height: 45px;
  border: 1px solid #047b40;
  display: flex;
  align-items: center;

  justify-content: center;
  border-radius: 40px;
  color: #554e4e;
  background-color: white;
}

.btn-white__dashboard-md {
  text-decoration: none;
  width: 140px;
  height: 50px;
  border: 1px solid #047b40;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-radius: 40px;
  color: #554e4e;
  background-color: white;
}

.btn-white__square {
  width: 7vw;
  height: 45px;
  background-color: white;
  color: #047b40;
  border: 1px solid #047b40;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn-green {
  width: 180px;
  height: 45px;
  border: 1px solid #047b40;
  color: white;
  background-color: #047b40;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  align-self: center;
  /* padding: 5px; */
}

.btn-green-md {
  width: 140px;
  height: 50px;
  border: 1px solid #047b40;
  color: white;
  background-color: #047b40;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  align-self: center;
}

.btn-green-md:hover {
  color: #ffffff;
  opacity: 0.8;
  transition: 0.3s ease-out;
  cursor: pointer;
}

.btn-green:hover {
  color: #ffffff;
  opacity: 0.8;
  transition: 0.3s ease-out;
  cursor: pointer;
}

.btn-green-save-option {
  width: 200px;
  height: 60px;
  border: 1px solid #047b40;
  color: white;
  background-color: #047b40;
  border-radius: 50px 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  align-self: center;
}

.btn-search {
  background-color: #047b40;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 50px 50px 0;
}

.btn-search:hover {
  opacity: 0.8;
  cursor: pointer;
}

.business--container__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}
.business-profile {
  align-items: center;
}

.capitalize {
  text-transform: capitalize;
}

.cancel-modal {
  position: absolute;
  top: 12px;
  right: 10px;
}

.cancel-modal__svg {
  width: 1.5em;
  height: 1.5em;
}

.cancel-modal__svg:hover {
  opacity: 0.5;
  cursor: pointer;
}

.card--container {
  height: 160px;
}

.card-send__invoice-container {
  width: 100%;
  line-height: 2.5;
}

.card-send__invoice {
  width: 100%;
  height: 100%;
  padding: 20px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
}

.card-text__invoice {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.cancel:hover {
  cursor: pointer;
  opacity: 0.4;
}

.cancel-icon__green {
  width: 49px;
  background: none;
  border: none;
  text-align: center;
}

.cancel-icon__green:hover {
  opacity: 0.8;
  cursor: pointer;
}

.check {
  margin-top: 14px;
}

.checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 2rem;
}

.checkbox__container {
  display: flex;
  align-items: center;
}

.checkbox__container > span > .checkbox > .checkbox__input > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox:--disabled {
  opacity: 0.7;
}

.checkbox__control {
  display: inline-grid;
  width: 1.4em;
  height: 1.4em;
  border-radius: 5px;
  border: 2px solid #047b40;
}

.checkbox__control:hover {
  cursor: pointer;
}

.checkbox__control > svg {
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  transform-origin: bottom left;
}

.checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";
}
.checkbox__input + * {
  grid-area: checkbox;
}

.currency {
  width: 120px;
  height: 100%;
}

.complete-registration__container {
  width: 70%;
  margin: auto;
}


input:focus + .checkbox__control {
  box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em #e7e9eb;
}

input:checked + .checkbox__control svg {
  transform: scale(1);
}

input:disabled + .checkbox__control {
  opacity: 0.7;
}
.close-remarks {
  align-self: center;
}

.customer-details__container {
  display: flex;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.customer-details {
  align-self: center;
}

.customer-form__container {
  max-width: 100%;
  width: 950px;
  margin: 40px auto 100px auto;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(7 56 134 / 40%);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgb(183 192 206 / 40%);
  padding: 20px;
  min-height: 45vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  /* height: 600px; */
}

.customer-form__container-modal {
  max-width: 100%;
  width: 950px;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(7 56 134 / 40%);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgb(183 192 206 / 40%);
  padding: 20px;
  height: 600px;
  min-height: 45vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}

.col-three {
  width: 400px;
}
.col-two {
  width: 200px;
}

.complete-registration-form {
  margin-top: 20px;
}

.dashboard-left__items {
  display: flex;
  gap: 1em;
  align-items: center;
}

.delete {
  width: 24px;
  height: fit-content;
}

.delete:hover svg.icon-tabler-trash {
  stroke: #fe0d0d;
  cursor: pointer;
  color: #fe0d0d;
}

.delete-invoice_table {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  height: 100%;
}

.details-form__invoice {
  width: 100%;
  margin-bottom: 20px;
}

.customer-list__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 60vh;
  max-height: 100%;
}

.customer-type__option {
  margin-bottom: 20px;
}

.dark-green {
  background-color: #047b40;
}

.dashboard__card {
  margin-top: 50px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  overflow: hidden;
  border-radius: 15px;
  padding: 30px 0 0 0;
  background: #fff;
}

.dashboard__card-list {
  box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  -webkit-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  overflow: auto;
  border-radius: 15px;
  background: #fff;
}

.dashboard--container {
  max-height: 100%;
  max-width: 100%;
  min-height: 100vh;
  background: #f7f8fa;
  line-height: 1.5rem;
  display: block;
  overflow: auto;
}

.dashboard--container::-webkit-scrollbar {
  display: none;
}

.dashboard--flex__container {
  background: #f7f8fa;
  height: 100%;
  /* width: 100%; */
  display: flex;
  width: calc(100% - 280px);
  flex-direction: column;
  /* position: fixed; */
  margin-top: 100px;
  margin-left: 280px;
}

.fa-bars {
  font-size: 30px;
  color: #047b40;
}
.fa-edit:hover {
  color: #2573bb;
  cursor: pointer;
}

.fa-eye:hover {
  color: #06c567;
  cursor: pointer;
}

.fa-trash-alt:hover {
  color: red;
  cursor: pointer;
}

.flex--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-invoices > .input-select__borderless::after {
  background: #047b40;
  margin-right: 0;
}

.filter-invoices > .input-select__borderless > select {
  font-size: 15px;
}

.flex--container__column {
  display: grid;
  gap: 2em;
}

.flex--container__preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex--container__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  margin: auto;
  width: 97%;
}

.filter-arrow-dropdown {
  position: absolute;
  left: 13rem;
  top: 0.7rem;
}

.filter-dropdown__container {
  display: flex;
  justify-content: space-between;
  /* max-width: 22rem; */
  position: relative;
}

.filter-dropdown__mg {
  margin: 19rem 0 0 -9rem !important;
}

.item-list__title > .btn-dropdown__borderless:focus + .links-table {
  display: grid;
}


.filter-items__mg {
  margin: -16px -128px !important;
}

.filter-reports {
  width: 700px;
}

.four-grids {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}



.forgot-pass-email {
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.forgot-pass {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.form--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: auto;
}

.form--container__auth {
  display: flex;
  flex-direction: column;
  height: 650px;
  width: 500px;
  padding: 20px;
  justify-content: space-evenly;
  z-index: 99;
  position: relative;
  background-color: #fff;
  align-items: center;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(7, 56, 134, 0.5);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.5);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.5);
}

.form--container__login {
  display: flex;
  flex-direction: column;
  min-height: 320px;
  max-height: 400px;
  justify-content: space-between;
  width: auto;
  align-items: center;
}

.footer__container {
  width: calc(100% - 280px);
  text-align: center;
  margin-top: 70px;
  margin-left: auto;
  bottom: 0;
  transform: translateX(-37px);
}

.green-highlight {
  width: 425px;
  background: #e2fef0;
  border: 1px solid #047b40;
  border-radius: 10px 10px 0 0;
  padding: 15px;
}

.green-highlight__table {
  background: rgba(4, 123, 64, 0.28);
  width: auto;
  padding: 1px;

  color: #047b40;
  border-radius: 15px;
  text-align: center;
  text-transform: capitalize;
}

.green-highlight__table--square {
  background-color: #c5fdce;
  width: 100px;
  position: absolute;
  text-align: center;
  border-radius: 3px;
  display: none;
  color: #047b40;
}
.green__table {
  width: 100%;
  border-collapse: collapse;
}

.green-text {
  color: #047b40;
  text-decoration: none;
  pointer-events: none;
}

.green-text-light {
  color: #06c567;
}

.green-text:hover {
  opacity: 0.8;
  cursor: pointer;
}

.green-text_title {
  color: #047b40;
  text-decoration: none;
}

.green-text__table {
  color: #0d9f24;
}

.grey-text__table {
  color: #554e4e;
  font-size: 15px;
}

.grey-highlight {
  background-color: #e5e5e5;
}

.grey-highlight__table {
  width: auto;
  padding: 1px;
  background: rgba(128, 130, 134, 0.29);
  color: #808286;
  border-radius: 15px;
  text-align: center;
  text-transform: capitalize;
}

.green_underline {
  border-bottom: 4px solid #06c567;
}

.green-underline__light {
  color: #06c567;
  cursor: pointer;
}

.grid--columns__two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.green-underline__light:hover {
  border-bottom: 1px solid #06c567;
}

.green-underline__dark-bold {
  border-bottom: 3px solid #047b40;
}

.grid--column__dashboard {
  display: grid;
  grid-template-columns: minmax(300px, 350px) 1fr;


  gap: 2em;
  width: 100%;
  margin: 0;
}

.grid--container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  gap: 3em;
  width: 100%;
}

.grid--container__dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  gap: 2em;
  height: inherit;
  width: 100%;
}

/* tests */

.h1-light {
  font-weight: 100;
  font-size: 26px;
}
.h1-bold {
  font-size: 26px;
  font-weight: 700;
}
.h1-medium {
  font-size: 26px;
  font-weight: 500;
}

.h3-medium {
  font-size: 20px;
  font-weight: 400;
}

.hamburger {
  display: none;
}

.header-nav__item {
  display: flex;
  align-items: center;
  padding-left: 40px;

  justify-content: space-between;
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-text__auth {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
}

.h-25 {
  height: 25px;
}

.highlight-green {
  background-color: #e2fef0;
}

.hr {
  border-bottom: 1px solid #bcb9b9;
  display: block;
}

.icon-tabler-eye:hover {
  stroke: #06c567;
  cursor: pointer;
}
.icon-tabler-edit:hover {
  stroke: #2573bb;
  cursor: pointer;
}

.icon-tabler-trash:hover {
  stroke: #fe0d0d;
  cursor: pointer;
}

.icon-width {
  width: 87px;
}

.illustration-card {
  margin-right: 80px;
}

.illustration-modal__container {
  text-align: center;
  margin-top: 60px;
}

.illustration-signup__container {
  width: 41rem;
}

.illustration--success {
  width: 45rem;
}

.info-svg {
  align-self: center;
  margin-left: 10px;
  cursor: pointer;
}

.info-svg:hover + .info-details__wrap {
  visibility: visible;
}

.info-details {
  width: 350px;
  position: absolute;
  right: 0.6rem;
  margin-top: 36px;
  margin-right: 0;
  background: white;
  z-index: 100;
  padding: 57px 20px 20px 20px;
  clip-path: polygon(
    96% 0,
    93% 13%,
    100% 13%,
    100% 100%,
    0 100%,
    0 13%,
    84% 13%
  );
}

.info-details__wrap {
  filter: drop-shadow(-1px 1px 2px rgba(50, 50, 0, 0.19));
  visibility: hidden;
}

.inner-dashboard__container {
  height: auto;
  width: auto;
  padding: 0 10px 0;
  width: 95%;
}

.inner-page--container {
  width: 100%;
  padding: 10px;
}

.inner-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 340px;
  margin-top: 20px;
}

.inner-nav-with-links {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: baseline;
}

/* .inner-mobile-navbar {
  padding: 15px;
} */

.inner-text {
  margin-top: 10px;
  text-align: center;
}

.input {
  border-radius: 10px;
  padding: 0 7px 0 7px;
  height: 47px;
  min-width: 300px;
  max-width: 100%;
  border: 1px solid #e5e5e5;
  outline: none;

  background-color: white;
  padding: 0px 20px 0 20px;
}

input[type="date"] {
  width: 200px !important;
}

.input_borderless {
  border: none;
  border-bottom: 1px solid #e5e5e5;
  max-width: 8rem;
  min-width: 50px;
  background: #f7f8fa;
}

.input-date-sm {
  max-width: 320px;
}

.input-select__green {
  height: 50px;
  width: 100%;
  border: none;
  outline: none;

  background-color: #f7f8fa;

  padding: 0 10px;
  color: #686868;
}
.input-select__save-option {
  /* width: 100%; */
  width: 180px;
  border-radius: 30px;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  height: 45px;
  line-height: 1.1;
  color: #fff;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  background-color: #047b40;
}

.input-select__save-option > select > option {
  color: #000000;
}

select,
.input-select__save-option:after {
  grid-area: select;
}
.input-select__save-option::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background: #fff;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

.input-select__save-option:hover {
  opacity: 0.8;
}

.input-login {
  border-radius: 10px;
  padding: 0 15px 0 15px;
  height: 50px;
  width: 458px;
  border: 1px solid #e5e5e5;
  outline: none;
  background-color: white;
}

.input-md {
  height: 45px;
  max-width: 300px;
  border: 1px solid #e5e5e5;
  outline: none;
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
  color: #686868;
  width: auto;
}

.input-invoice__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-invoice {
  /* min-width: 733px;
  max-width: 800px; */
  width: 100%;
}

.input-invoice-medium {
width: 95%;
}

.input-md__select {
  height: 45px;
  width: 100%;
  border: 1px solid #e5e5e5;
  outline: none;
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
  color: #686868;
}

.input-select__borderless {
  /* width: 100%; */
  min-width: 10ch;
  max-width: 30ch;

  border-radius: 10px;
  /* padding: 0.25em 0.45em; */
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.1;
  /* height: 45px; */
  color: #686868;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
}

.input-select__borderless::after {
  content: "";
  width: 0.8em;
  height: 0.5em;

  background: #686868;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);

  justify-self: end;
  margin-right: 20px;
}

select,
.input-select__borderless::after {
  grid-area: select;
}

.input-sm {
  height: 45px;
  width: 125px;
  border: 1px solid #e5e5e5;
  outline: none;
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
  color: #686868;
}

.input-xs {
  width: 50px;
  border: 1px solid #e5e5e5;
  outline: none;
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
  color: #686868;
}

input[type="text"] {
  display: block;
  height: 45px;
}

.input--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invoices {
  line-height: 3em;
}

.invoice-extra-details {
  position: absolute;
  width: 490px;
  background: white;
  border: 1px solid grey;
  padding: 15px;
  border-radius: 3px;
  right: 21.5rem;

  z-index: 20;
  /* top: 15rem; */
  visibility: hidden;
}

.invoices > span {
  display: block;
}

.invoice-accounts__container {
  display: flex;
  justify-content: space-between;
}

.invoice-card__container {
  padding: 5px;
}

.invoice-card__container > .dashboard__card-list {
  margin-top: 0;
}

.invoice__cards {
  width: auto;
  /* height: 150px; */
  padding: 20px 40px;
  background: white;
  -webkit-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  border-radius: 10px;
}

.invoice-card-right__items {
  align-self: flex-end;
  width: 170px;
  text-align: end;
  margin-bottom: 30px;
}

.invoice-card-left__items {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 40px;
}

.invoice-form__container {
  width: 90%;
}

.invoice-submit__container {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  margin: auto;
  margin-top: 30px;
}

.invoice__table {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.invoice-tabs {
  display: flex;
  justify-content: space-between;
  width: 265px;
}

.invoice-no {
  background-color: #0d9f24;
  width: 100%;
  text-align: center;
  color: white;
}

.item-mobile__container {
  display: none;
}

.item-and-invoice {
  width: 100%;
}

.item-card {
  padding: 15px;
  border-radius: 15px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
}

.item-card__container {
  padding: 0 5px 5px 5px;
}


.items-left {
  display: flex;
  align-items: center;
}

.item-lists {
  background: #fff;
  width: 300px;
  border-radius: 15px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  margin: 4px;
  max-height: 788px;
  overflow: auto;
  min-height: fit-content;
}

.item-list__title {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.item-price {
  padding: 15px;
}


.label-dashboard {
  display: inline-flex;
  justify-content: center;
  margin-left: 10px;
}

.label-dashboard:hover {
  cursor: pointer;
}

.label-dashboard > p {
  margin-top: 3px;
}

.last-col__table {
  width: 170px;
}

.landing-left__container {
  /* width: 100%; */
  /* padding-left: 20px; */
}

.left--items {
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: space-around;
}

.light-green {
  background-color: #c5fdce;
}

.links__container {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 40vw;
  margin-bottom: 30px;
}

.links-table {
  display: none;
  background-color: #fff;
  border: 1px solid #686868;
  width: 150px;
  position: absolute;
  z-index: 99;
  gap: 0.2em;
  padding: 10px 0;
  top: 2.5rem;
  right: 1rem;
}

.link-text {
  text-decoration: none;
  color: #554e4e;
  margin: 0 0 0 12px;
  width: fit-content;
}

.link-text__title {
  text-decoration: none;
  color: #808286;
  margin: 0 0 0 12px;
  text-transform: uppercase;
}

.link-text:hover {
  opacity: 0.7;
}

.list-details {
  color: #808286;
}



.logo-details {
  align-self: center;
}

.login-header {
  padding-left: inherit;
  align-self: baseline;
}


.login-form__container {
  height: 72%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.logo-profile {
  margin: 5vh 0 0vh 0;
}

.logo-mobile {
  display: none;
}
.main--container {
  position: absolute;
}

.mg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}

.mg-t-2 {
  margin-top: 20px !important;
}

.mg-b-1 {
  margin-bottom: 10px;
}

.mobile-side-navbar__container {
  left: -300px;
  display: none;
}

.mobile-nav__item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.mobile-navbar__item--container {
  margin-top: 20px;
}

.modal-btn__container {
  width: 50%;
}

.modal-card {
  position: fixed;
  top: 40%;
  left: 35%;
  width: 35vw;
  height: 30vh;
  background: white;
  -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.4);
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.4);
  /* display: none; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px;
  margin: auto;
}

.modal-overlay {
  position: fixed;
  top: 3rem;
  /* justify-self: center; */
}

.modal-overlay__container {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  display: none;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  /* display: flex; */
  z-index: 99;

  /* &.one {
    transform:scaleY(.01) scaleX(0);
    animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    .modal-background {
      .modal {
        transform:scale(0);
        animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &.out {
      transform:scale(1);
      animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal-background {
        .modal {
          animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
    }
  } */
}

/* MODAL STUFF */

/* .modal-overlay__container > .green-highlight__table--square {
  transform:scaleY(.01) scaleX(0);
  animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-overlay__container > .modal-overlay {
  transform:scale(0);
        animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-overlay__container > .out {
  transform:scale(1);
  animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-overlay__container > .out > .modal-overlay >.customer-form__container {
  animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
} */

.more--invoice__button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px;
}

.more-details {
  display: none;
}

.navbar--container {
  height: 76px;
  z-index: 99;
  background-color: white;
  width: 100vw;
  color: #047b40 !important;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  top: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
}

.navbar--container__mobile {
  display: none;
}

.navbar-item__container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 100%;
}

.nav-text {
  margin-left: 30px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.notes--container {
  display: grid;
  /* justify-content: space-between; */
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
}

.no-items {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e7e9eb;
}

.notes-preview__container {
  margin-top: 40px;
  display: grid;

  gap: 1.5em;
}

.onboarding-cancel {
  margin-left: 98%;
  display: block;
  height: 27px;
  width: fit-content;
}

.onboarding-dashboard {
  padding: 0 !important;
  margin-bottom: 20px;
}

.onboarding-list {
  border-right: 1px solid #bcb9b9;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.onboarding-list__details {
  padding: 25px;
}

.onboarding-line {
  border-bottom: 1px solid #bcb9b9;
}

.options {
  min-width: 370px;
  width: auto;
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 27px;
  width: 26px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.8rem;
  outline: none;
  /* border-radius: 50%; */
  position: relative;
  z-index: 7;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: #047b40;
}
.option-input:checked::before {
  height: 25px;
  width: 25px;
  margin: auto;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-input.checkbox-radio {
  border-radius: 50%;
}
.option-input.checkbox-radio::after {
  border-radius: 50%;
}

.orange-text {
  color: #fe5824;
}

.pages-btn__container {
  display: flex;
  justify-content: space-between;
  width: 260px;
}

.page-btn {
  width: 32px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 3px;
}

.page-no {
  align-self: flex-end;
}

.green-background {
  background-color: var(--green-color);
}

.page-btn__green:hover {
  background-color: var(--green-color);
}

.page-btn__off-white {
  background-color: #e5e5e5;
}

.page-btn:hover {
  background-color: in;
  color: white;
  cursor: pointer;
}

.pd-total {
  padding: 10px;
}

.purple-text {
  color: #5d24fe;
}

.plus {
  display: flex;
 width: 100%;
  justify-content: space-between;
}

.plus-icon {
  width: 40px;
  height: 40px;
  background: #047b40;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
}

.plus-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.plus-icon:hover + .green-highlight__table--square {
  display: inline-block;
}

.preview-card__container-modal {
  width: 95%;
  margin: auto;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  padding: 20px;
}

.preview-invoice__card {
  min-height: 70vh;
  max-height: 100%;
  width: 100%;

  -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  background-color: #fff;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.preview-reports__card {
  min-height: 70vh;
  max-height: 100%;
  width: 800px;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(183 192 206 / 40%);
  -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
  box-shadow: 0px 1px 5px 0px rgb(183 192 206 / 40%);
  background-color: #fff;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
}


.preview-invoice__submit {
  display: flex;
  justify-content: space-between;
  width: 420px;
}

.preview-invoice__total {
  width: 320px;
  align-self: flex-end;
  margin-top: 70px;
}

.profile-bank-accounts {
  align-self: flex-start;
}

.p-text__textarea {
  border: none;
  width: 100%;
  height: 100%;
  background: none;
  overflow: hidden;
  resize: none;
}

.p-text__textarea:focus {
  border-bottom: 1px solid #554e4e;
}

.red-highlight__table {
  width: auto;
  padding: 1px;
  background: rgba(254, 13, 13, 0.29);
  color: #fe0d0d;
  border-radius: 15px;
  text-align: center;
  text-transform: capitalize;
}

.red-text__table {
  color: #fe0d0d;
}

.save-options {
  display: flex;
  text-decoration: none;
  outline: none;
}

select {
  outline: none;
}

.search-input {
  height: 50px;
  border-radius: 50px 0 0px 50px;
  background-color: white;
  padding: 0px 15px 0 15px;
  border: 1px solid #e5e5e5;
}

.search--input {
  display: flex;
}

select {
  appearance: none;

  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  color: inherit;
}

.select {
  min-width: 200px;
  max-width: 300px;
  border-radius: auto;
  cursor: pointer;
  line-height: 1.1;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
}

select,
.select:after {
  grid-area: select;
}
.select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: #686868;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
  margin-right: 15px;
}

select::-ms-expand {
  display: none;
}

.select-bank {
  display: inline-block;
}

.select-green option {
  background-color: red;
}

.send-invoice__inner-card {
  width: 500px;
  margin: auto auto 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e5e5e5;
  line-height: 2.5;
}

.side-line {
  display: block;
  height: 40px;
  transform: rotate(-20deg);
}

.short {
  width: 400px;
  height: 40px;
  /* height: 60px; */
}

.side-lines-left {
  width: 500px;
  height: 40px;
  /* position: fixed; */
  /* left: -2.5rem;
  bottom: 25px; */
}

.side-lines-left__container {
  width: auto;
  height: fit-content;
  position: absolute;
  left: -2rem;
  /* top: 82%; */
  bottom: 6rem;
  transform: rotate(-20deg);
}

.side-lines-right {
  width: 332px;
  height: auto;
  position: fixed;
  right: -40px;
  top: 38%;
  z-index: -1;
  transform: rotate(-25deg);
}

.side-line-right {
  height: 40px;
  display: block;
}

.side-nav {
  width: 280px;
  height: 100%;
  color: #000000;
  position: fixed;
  box-shadow: 2px 1px 2px 0px rgb(183 192 206 / 20%);
  left: 0;
  top: 76px;
  z-index: 9;
  background: white;
  overflow-x: scroll;
}

ul {
  padding: 0 !important;
}

.side-nav__item {
  width: 100%;
  padding-left: 40px;
  display: flex;
  height: inherit;
  align-items: center;
}

.side-nav__item:hover path {
  fill: #047b40;
  transition: 0.3s ease-in;
}

.side-nav__item:hover > a {
  color: #047b40;
  transition: 0.3s ease-in;
}

.side-nav__item:hover {
  cursor: pointer;

  border-radius: 0 50px 50px 0;
  background: #e2fef0;
  color: #047b40;
  transition: 0.8s ease-in-out;
}

.submit {
  display: flex;
  width: 50%;
  margin: 40px auto 0;
  justify-content: space-evenly;
}

.submit-invoice {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-content: center;
  height: auto;
}

.submit-invite {
  display: flex;
  width: 400px;
  justify-content: space-evenly;
  align-content: center;
  height: auto;
}

.tag-status {
  border: 2px solid var(--green-color);
  width: 70px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--green-color);
  text-transform: uppercase;
  font-weight: 700;
}

.tag-and-name {
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tag-and-name > .logo-details > img {
  width: 100px;
}

.text {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.user-and-role-name > span {
  display: block;
  font-size: 11px;
  color: #686868;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}
.textarea {
  width: 100%;
  padding: 20px;
  height: 160px;
  border-radius: 10px;
  border: 1px solid #e7e9eb;
  resize: none;
}

.textarea-invite {
  min-width: 733px;
  max-width: 800px;
  border-radius: 10px;
  border: 1px solid #e7e9eb;
  resize: none;
  height: 300px;
}

.textarea-invite__container {
  display: flex;
  max-width: 900px;
  justify-content: space-between;
}

.textarea-borderless {
  width: 100%;
  /* padding: 20px; */
  height: 160px;
  resize: none;
  border: none;
  text-align: left;
}

.text-lg {
  font-size: 20px;
}

.text-md {
  font-size: 15px;
}

.text-bold {
  font-weight: 700;
}

.text-light {
  font-weight: 300;
}

.text-underline {
  text-decoration: underline;
}

.empty-tax__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
}

.error {
  color: red;
  font-size: 12px;
}

.table-div {
  width: auto;
  width: -moz-available;
  border-collapse: collapse;
  width: 100%;
}

.table-list__container {
  padding: 5px;
}

.table-div > tbody > .border-grey > .reset-table {
  width: 100%;
}
.th {
  background: #e2fef0;
  color: #047b40;
}

.th_invoice {
  border-bottom: 2px solid #e7e9eb;
}

.th_invoice-preview {
  background: #e2fef0;
  color: #000;
}

td {
  padding: 1.5em;
}
tr {
  width: 100%;
}

.amount-td:hover {
  cursor: pointer;
}

.amount-td:hover + .invoice-extra-details {
  visibility: visible;
}

th {
  padding: 1.5em;
  text-align: left;
}

.time {
  font-weight: 700;
  font-size: 15px;
}
.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  height: 100%;
}

.title-bar {
  display: flex;
  width: inherit;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title-bar__card--modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-card {
  width: min-content;
  align-self: flex-end;
}

.total--container {
  display: flex;
  flex-direction: column;
  line-height: 3;
  margin-top: 50px;
}

.toggle-btn__container-modal {
  display: flex;
}

ul {
  list-style-type: none;
}

.uppercase {
  text-transform: uppercase;
}

.user-email {
  color: #737171;
  font-size: 13px;
  white-space: nowrap;
}

.user-name {
  color: #047b40;
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
}

.verify-circle {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #ffffff;

  -webkit-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  -moz-box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  box-shadow: 0 2px 4px 0 rgba(183, 192, 206, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 20vh;
  text-align: center;
}

.verification-text__success {
  text-align: center;
  margin-top: 20px;
}

.view-icon__table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  position: relative;
}

.view-item-list__container {
  display: flex;
}


.yellow-highlight__table {
  background: rgba(251, 228, 21, 0.46);
  width: auto;
  padding: 1px;

  color: #fb6815;

  border-radius: 15px;
  text-align: center;
  text-transform: capitalize;
}

.yellow-text__table {
  color: #f8d803;
}

.white__table {
  width: 70vw;
}

.cust-modal-height__mobile {
  height: 500px;
    }

/* media queries */
/* Phone and tablet only */
@media only screen and (max-width: 1024px) {
  .address {
    width: 100%;

    display: grid;
    gap: 1.5em;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .auth-card__container {
    /* display: block; */
    grid-template-columns: 1fr;
  }

  .auth--container {
    margin-bottom: 20px;
    width: 100%;
  }

  .attachments {
    width: 100%;
  }

  .body--container {
    width: 90vw;
  }

  .card--container {
    height: 100%;
  }

  .customer-list__container {
    height: 100%;
  }

  .card-send__invoice-container {
    width: 100%;
  }
  .dashboard--flex__container {
    width: 100%;
    margin-left: 0;
  }

  .details-form__invoice {
    min-width: auto;
    max-width: 100%;
  }

  .filter-reports {
    width: 100%;
  }

  .form--container__auth {
    top: 0rem !important;
    height: 650px !important;
  }

  .transaction-header__container {
    display: grid;
  }

  .green-highlight__table {
    background-color: transparent;
  }

  .grey-highlight__table {
    background-color: transparent;
  }

  .grid--container__dashboard {
    grid-template-columns: 1fr;
  }

  .grid--column__dashboard {
    grid-template-columns: repeat(2,1fr);
  }
  .h1-bold {
    font-size: 20px;
  }

  .hamburger {
    display: block;
  }

  .header-text {
    width: 100%;
  }

  .header-nav__item {
    margin-left: 15px;
    padding: 0;
  }

  .inner-dashboard__container {
    width: 100%;
    height: 100%;
    margin-left: 0;

  }

  .inner-dashboard__container-business-profile {
    padding: 1.5vh;
    width: 100%;
    height: 100%;
    margin-left: 0;
  }

  .inner-page--container {
    padding: 0;
    height: 100%;
  }

  .input-invoice {
    min-width: auto;
    max-width: 100%;
  }

  .input-login {
    width: 100%;
  }

  .invoice-form__container {
    width: 100%;
    margin-top: 30px;
  }

  .landing-left__container {
    display: none;
  }

  .logo-mobile {
    display: flex;
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
  }

  .left--items {
    display: none;
  }

  .links__container {
    width: 100%;
  }

  .login-header {
    margin-bottom: 25px;
  }

  .logo-dashboard {
    display: none;
  }

  .navbar--container {
    width: 100%;
  }


  .red-highlight__table {
    background-color: transparent;
  }

  .send-invoice__container {
    width: 100%;
  }

  .side-lines-right {
    display: none;
  }

  .mobile-side-navbar__container {
    display: block;
    width: 300px;
    height: 100%;
    background: #fff;
    position: fixed;
    z-index: 999;
    top: 0;
    min-height: 100vh;
    max-height: 100%;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    transition: 0.6s ease-in-out;
  }

  .side-lines-left {
    width: 42rem;
  }

  .side-lines-left__container {
    bottom: 33rem;
  }

  .short {
    width: 30rem;
  }
  .side-nav {
    width: 0;
    display: none;
  }

  .submit {
    width: 50%;
  }

  .submit-invoice {
    width: 100%;
  }

  .title-bar {
    display: flex;
    width: inherit;
  }

  td,
  th {
    padding: 0.7rem;
  }

  .yellow-highlight__table {
    background-color: transparent;
  }

  .customer-form__container {
    width: 100%;
  }
}

/* Ipad pro only */

@media (max-width: 1366px) {
  .form--container__auth {
    align-self: center;
  }
  .input--container {
    width: 100%;
  }
  .transaction-header__container {
    display: grid;
    grid-template-columns: 1fr !important;
  }
}

/* Phones only */
@media (max-width: 600px) {
  .account-details__container {
    width: 100%;
  }

  .auth-container__verification {
    padding: 2rem;
  }

  .attachments {
    width: 100%;
  }

  .address {
    display: inline-flex;
    flex-direction: column;
  }

  .cust-modal-height__mobile {
height: 400px;
  }

  .auth--container {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .body--container {
    width: 100%;
    max-height: 100%;
    padding: 5px;
  }

  .btn-green {
    font-size: 12px;
    padding: 7px;
  }

  .btn-dropdown:hover + .links-table {
    display: grid;
    transition: all 0.5s ease-in-out;
  }

  .business--container__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: normal;
    margin-bottom: 10px;
  }

  .card--container {
    height: 100%;
  }

  .card-send__invoice-container {
    width: 100%;
  }

  .card-text__invoice {
    display: block;
  }

  .company--owner {
    display: block;
  }

  .complete-registration__container {
    width: 100%;
  }
  .dashboard__card {
    width: auto;
  }

  .dashboard--flex__container {
    width: 100%;
    margin-left: 0;
  }

  .dashboard-left__items {
    display: grid;
    gap: 1em;
    align-items: center;
    justify-items: center;
  }

  .dashboard__card-list {
    margin-top: 0;
  }

  .details-form__invoice {
    max-width: 100%;
    min-width: 250px;
  }

  .flex--container__preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .form--container {
    width: 100%;
  }

  .form--container__login {
    width: 100%;
    padding: 10px;
  }

  .footer__container {
    transform: none;
    position: relative;
    width: 100%;
  }

  .filter-dropdown__container {
    /* width: 9rem; */
  }

  .green-highlight {
    width: 100%;
  }

  .green-highlight__table {
    background: none;
  }

  .grey-highlight__table {
    background: none;
  }

  .grid--container {
    grid-template-columns: 1fr;
  }
  .grid--column__dashboard {
    grid-template-columns: 1fr;
  }

  .header-text {
    height: auto;
  }

  .h1-light {
    font-size: 20px;
  }
  

  .input-md {
    width: 100%;
  }
  .input-md__select {
    width: 100%;
  }
  .input-invoice {
    min-width: 100%;
    max-width: 100%;
  }

  .input-invoice-medium {
    min-width: 82%;
    max-width: 90%;
  }


  .inner-mobile-nav {
    padding: 10px;
    display: none;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .invoice-extra-details {
    left: 0;
    right: 0;
    width: 85%;
    margin-left: 10px;
  }

  .invoice-accounts__container {
    display: inline-grid;
    gap: 1em;
    grid-template-columns: 1fr;
  }

  .invoice__cards {
    padding: 10px 15px;
  }

  .input-invoice__container {
    display: block;
  }

  .invoice-submit__container {
    display: grid;
    gap: 1em;
    width: 100%;
  }

  .item-lists {
    width: 100%;
  }

  .item-card__container {
    display: none;
  }

  .item-mobile__dropdown-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5em;
  }

  .illustration--success {
    width: 20rem;
  }

  .logo {
    margin-top: 10vh;
  }

  .mail-box > svg {
    width: 50px;
    height: 50px;
  }

  .mobile-side-navbar__container {
    display: block;
    width: 300px;
    height: 100%;
    background: #fff;
    position: fixed;
    z-index: 999;
    top: 0;
    min-height: 100vh;
    max-height: 100%;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    transition: 0.6s ease-in-out;
  }
  .mobile-nav__item {
    width: 95%;
    padding-left: 18px;
    display: flex;
    height: 60px;
    align-items: center;
  }

  .mobile-nav__item:hover path {
    fill: #047b40;
    transition: 0.3s ease-in;
  }

  .mobile-nav__item:hover {
    cursor: pointer;
    border-radius: 0 50px 50px 0;
    background: #e2fef0;
    color: #047b40;
    transition: 0.3s ease-in;
  }
  .mobile-title {
    padding: 18px;
    line-height: 2em;
  }

  .more-details {
    display: block;
  }

  .more-details:focus + .item-mobile__container {
    display: block;
  }

  .red-highlight__table {
    background: none;
  }

  .route--container {
    display: flex;
    align-items: center;
    width: 55%;
    justify-content: space-between;
  }

  .navbar--container {
    width: 100%;
  }

  .navbar--container__mobile {
    /* width: 100%;
    height: 10vh;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    -moz-box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    box-shadow: 0px 1px 5px 0px rgba(183, 192, 206, 0.4);
    background: #fff;
    position: fixed;

    z-index: 9999; */
    display: none;
  }
  .nav-route {
    color: #047b40;
    font-size: 18px;
    font-weight: 500;
  }

  .note {
    width: 95%;
    margin: auto;
  }

  .notes--container {
    width: 100%;
    display: block;
  }
  .notes-preview__container {
    display: inline-block;
  }

  .onboarding-list {
    border-right: none;
  }

  .onboarding-list__details {
    display: none;
  }

  .preview-invoice__container {
    padding: 0;
  }

  .preview-invoice__submit {
    display: grid;
    gap: 1em;
    width: 100%;
    justify-content: center;
  }

  .preview-invoice__total {
    width: 100%;
  }

  .preview-invoice__card {
    padding: 10px;
  }

  .links__container {
    display: none;
  }

  .links-table {
    right: 52px;
  }

  .more--invoice__button {
    display: block;
  }

  .save-options {
    height: 55px;
  }

  .send-invoice__container {
    width: 100%;
  }

  .send-invoice__inner-card {
    width: 100%;
  }

  .side-lines-left__container {
    display: none;
  }

  .submit {
    display: grid;
    gap: 1em;
    width: 100%;
  }

  .submit-invoice {
    width: 100%;
    display: grid;
    justify-content: center;
    gap: 1em;
    margin-top: 20px;
  }
  .table-div {
    overflow-x: scroll;
  }

  .text {
    font-size: 12px;
  }

  .textarea {
    width: 100%;
    min-width: auto;
  }
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .title-bar {
    align-items: center;
    display: grid;
    justify-content: center;
    margin-bottom: 30px;
  }

  .total--container {
    width: 100%;
  }

  .total-card {
    width: 100%;
  }

  .verify-circle {
    width: 80px;
    height: 80px;
  }
  .verify-text {
    margin-top: 25px;
    width: 90%;
    height: auto;
  }

  .yellow-highlight__table {
    background: none;
  }
  .view-icon__table {
    width: 30px;
  }

  .view-item-list__container {
    display: block;
  }

  .view-icon__table > a {
    display: none;
  }
  .input--container {
    width: 100%;
  }

  .form--container__auth {
    align-self: center;
    width: 100%;
  }
  .auth-card__container {
    padding-top: 0px;
  }
}
/* tablets */

@media only screen and (max-width: 1576px) and (min-width: 1024px) {
  .card-text__invoice {
    margin-bottom: 20px;
  }

  .form--container__auth__forgot-pass {
    width: 100%;
    height: 400px;
  }

  .grid--container {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid--container__dashboard {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    width: 100%;
    margin: 0;
  }
  .hamburger {
    display: block;
  }

  .illustration-signup__container {
    height: 27rem;
    width: 27rem;
  }

  .invoice__cards {
    width: auto;
    padding: 20px 10px;
  }

  .invoice-form__container {
    width: 100%;
  }

  .inner-dashboard__container {
    height: auto;
    width: auto;
    /* padding: 15vh 10px 0; */
  }

  .dashboard--container > div > .dashboard--flex__container {
    overflow: hidden;
    margin-top: 110px;
  }

  .inner-page--container {
    padding: 0 10px;
  }

  .input {
    height: 45px;
  }

  .item-lists {
    max-height: 857px;
  }

  .landing-left__container {
    padding-left: 40px;
  }

  .left--items {
    display: inline-flex;
    width: 150px;
  }

  .links__container {
    width: 75%;
  }

  .navbar--container {
    width: 100%;
  }

  .title-bar {
    flex-direction: inherit;
  }

  td,
  th {
    font-size: 12px;
    width: 150px;
  }

  th,
  td {
    padding: 1.43em;
    text-align: left;
  }

  .send-invoice__inner-card {
    margin-bottom: 20px;
  }

  .select {
    width: auto;
  }

  .side-lines-right {
    display: none;
  }

  .side-lines-left__container {
    display: none;
  }

  .white__table {
    table-layout: fixed;
    width: 100%;
  }
}


/* iPhone 5 only */
@media only screen and (max-width: 320px)  {
  div.input-select__borderless {
    min-width: 10ch;
  }
}
.form--container__auth__forgot-pass {
  width: 100%;
  height: 200px;
}

.custom-input {
  height: 55px;
  background: white;
  border-radius: 10px;
}

.css-1okebmr-indicatorSeparator{
  max-height: 2rem;
}

.css-tlfecz-indicatorContainer > svg{
  margin-top: -.9rem
}

.css-1wa3eu0-placeholder{
  top: 40% !important;
}

@media (max-width: 1500px) {
  .view-item-list__container {
      flex-direction: column-reverse;
  }
}



